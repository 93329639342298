import React, { useState } from "react"

import Link from "../utils/link"
import HeaderLinks from "./header-links/HeaderLinks"

import MavenLogo from "../assets/images/MavenLogo.svg"

import "./header.scss"

const header = () => {
  const [show, setShow] = useState(false)

  const toggler = () => {
    setShow(!show)
  }

  let btnClass = "header__menuBtn"
  let linkClass = "header__links--mobile"
  let backDropClass = ""
  let headerClass = "header"
  if (show) {
    btnClass = "header__menuBtn header__menuBtn--active"
    linkClass = "header__links--mobile header__links--mobile--active"
    backDropClass = "header__backdrop"
    headerClass = "header header--active"
  }

  return (
    <>
      <div className={headerClass}>
        <div className="header__container">
          <div className="header__logo">
            <Link to="/" title="Home">
              <img src={MavenLogo} alt='Maven' />
            </Link>
          </div>
          <div className="header__navigation">
            <div className="header__menu">
              <Link to="/" className="header__menuLink">
                /
              </Link>
              <button onClick={toggler} className={btnClass}>
                /
              </button>
            </div>
            <div className="header__links--desktop">
              <HeaderLinks />
            </div>
          </div>
        </div>
      </div>
      <div className={backDropClass}></div>
      <div className={linkClass}>
        <HeaderLinks />
      </div>
    </>
  )
}

export default header
